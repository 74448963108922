import { useState, useEffect, useContext, useRef } from "react";

import ActiveContext from '../context/ActiveContext';
import ActiveIndexContext from '../context/ActiveIndexContext';

const Slide = props => {

  const casestudy = props.casestudy;
  const caseStudies = props.caseStudies;
  const {active, setActive} = useContext(ActiveContext);
  const {activeIndex,setActiveIndex} = useContext(ActiveIndexContext);

  const slideTitle = useRef(null);
  const slideDescription = useRef(null);
  const slideContent = useRef(null);

  const safeIndex = index => {
    if (index < 0) return caseStudies.length - 1;
    if (index >= caseStudies.length) return 0;
    return index;
  }

  const handleClick = incr => {
    let newIndex = safeIndex(activeIndex + incr);
    setActive(caseStudies[newIndex]);
  } 

  const handlePrevClick = () => handleClick(-1);
  const handleNextClick = () => handleClick(1);

  useEffect(() => {
    const parser = new DOMParser();
    const content = parser.parseFromString(casestudy.content.rendered, 'text/html').documentElement;
    const takeawaysContent = Array.from(content.querySelectorAll('.jump-nav-anchor[name="#takeaways"] + .wp-block-group > .wp-block-group__inner-container > *:not(h2)'));
    if (takeawaysContent.length) {
      takeawaysContent.unshift(document.createElement("h4"));
      takeawaysContent[0].innerText = "Key Takeaways";
    }

    slideTitle.current.innerHTML = (props.index + 1) + ". " + casestudy.title.rendered;
    slideDescription.current.innerHTML = casestudy.excerpt.rendered;

    const fragment = document.createDocumentFragment();
    takeawaysContent.forEach(node => fragment.appendChild(node));
    slideContent.current.appendChild(fragment);
  },[]);

  return (
    <article className={`post__preview post__preview-casestudy gallery__slide ${active ? (casestudy.id === active.id ? 'active' : '') : ''}`}>
      <header className={'post__header'}>
        <h3 className={'post__title'} ref={slideTitle}></h3>
        <div className={'post__description'} ref={slideDescription}></div> 
      </header>
      <div className={'gallery__actions'}>
        <a className={'btn btn-contrast gallery__cta'} href={active ? active.link : '#'}>Read the Full Case Study</a>
        <div className={'gallery__nav'}>
          <button className={'gallery__nav__item gallery__nav__item-prev btn btn-icon'} onClick={handlePrevClick} aria-label={"previous slide"}>
            <span class="visually-hidden">Previous Slide</span> 
            <i className={'bi bi-caret-left-fill'}></i>
          </button>
          <button className={'gallery__nav__item gallery__nav__item-next btn btn-icon'} onClick={handleNextClick} aria-label={"next slide"}>
            <span class="visually-hidden">Next Slide</span> 
            <i className={'bi bi-caret-right-fill'}></i>
          </button>
        </div>
      </div>
      <div className={'post__content'} ref={slideContent}></div>
    </article>
  )
}

export default Slide;