import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { PostProvider } from './context/PostContext';

import App from './App.js';

//Bootstrap
const elems = Array.from(document.querySelectorAll('.react-slideshow-root'));

elems.forEach((elem,e) => {
  const root = ReactDOM.createRoot(elem);
  root.render(
    <React.StrictMode>
      <PostProvider value={{
        type: elem.dataset.postType,
        id: elem.dataset.postId,
        isFront: elem.dataset.isFront === 'true'
      }}>
        <App />
      </PostProvider>
    </React.StrictMode>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
