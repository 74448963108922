import { useState, useEffect, useContext, useRef } from "react";
import PostContext from '../context/PostContext';
import ActiveContext from '../context/ActiveContext';
import ActiveIndexContext from '../context/ActiveIndexContext';

import Slide from './Slide';
import GalleryImage from './GalleryImage';



export const Gallery = props => {
  const getActiveIndex = () => {
    return active ? caseStudies.findIndex(c => c.id === active.id) : undefined;
  }

  const caseStudies = props.caseStudies;
  const current = useContext(PostContext);
  const {active, setActive} = useContext(ActiveContext);
  const {activeIndex,setActiveIndex} = useContext(ActiveIndexContext);
  const [hasSiblingCarousel, setHasSiblingCarousel] = useState(false);
  const [siblingCarousel, setSiblingCarousel] = useState(undefined);

  const containerInner = useRef(null);

  useEffect(() => {
    const containerOuter = containerInner.current.parentElement;
    //Sync with previous sibling carousel, if it exists
    const containerSibling = containerOuter.previousElementSibling;
    if (containerSibling && containerSibling.classList.contains('component-carousel')) {
      const carousel = containerSibling.querySelector(".gallery-carousel.auto-cycle");
      if (carousel) {
        setSiblingCarousel(carousel);
        setHasSiblingCarousel(true);
        carousel.addEventListener("carouselActiveChanged", e => {
          setActive(caseStudies[e.detail.activeItem]);
        })
      }
    }
  },[]);

  useEffect(() => {
    const index = getActiveIndex();
    setActiveIndex(index);
    if (hasSiblingCarousel) {
      siblingCarousel.dispatchEvent(new CustomEvent("galleryActiveChanged", {
        detail: {
          activeItem: index
        }
      }));
    }
  },[active]);

  return (
    <div className={'component__inner'} ref={containerInner}>
      <div className={'container-wide'}>
        <div className={'component__primary'}>
          <div className={'gallery__slides'}>
            {caseStudies.length && caseStudies.map((casestudy,c) => {
              return (
                <Slide casestudy={casestudy} caseStudies={caseStudies} index={c}></Slide>
              )
            })}  
          </div> 
        </div> 
        <div className={'component__secondary'}>
          <svg viewBox="0 0 1000 1000" className={`arc arc-contrast`}><circle cx="500" cy="500" r="498" vector-effect="non-scaling-stroke" /></svg>
          <div className={'gallery__images'}>
            {caseStudies.length && caseStudies.map((casestudy,c) => {
              return (
                <GalleryImage casestudy={casestudy}></GalleryImage>
              )
            })}  
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
