import { useState, useEffect, useContext } from 'react';
import { ActiveProvider } from './context/ActiveContext';
import { ActiveIndexProvider } from './context/ActiveIndexContext';
import PostContext from './context/PostContext';

import Gallery from './components/Gallery';

const apiBase = process.env.NODE_ENV === 'development' ? "https://edf-casestudies.localhost.com/wp-json/wp/v2" : "/wp-json/wp/v2";

const App = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [active, setActive] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(undefined);
  const current = useContext(PostContext);

  const apiRoutes = {
    caseStudies: `${apiBase}/casestudy?order=asc&_embed&acf_format=standard`
  }

  useEffect(() => {

    fetch(apiRoutes.caseStudies)
      .then((response) => response.json())
      .then((data) => {
        setCaseStudies(data);
        if (current.type === 'casestudy') {
          const currentIndex = data.findIndex(casestudy => casestudy.id == current.id);
          const nextIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1;
          setActive(data[nextIndex]);
          setActiveIndex(nextIndex);
        } else {
          setActive(data[0]);
          setActiveIndex(0);
        }
      });

  }, []);

  return (
    <ActiveProvider value={{active,setActive}}>
    <ActiveIndexProvider value={{activeIndex,setActiveIndex}}>
      {caseStudies && caseStudies.length && (
        <Gallery caseStudies={caseStudies} />
      )}
    </ActiveIndexProvider>
    </ActiveProvider>
  );
}

export default App