import { useState, useEffect, useContext } from "react";

import ActiveContext from '../context/ActiveContext';

const GalleryImage = props => {
  const casestudy = props.casestudy;
  const {active, setActive} = useContext(ActiveContext);

  //Look for secondary image
  let media = casestudy.acf.secondary_image;
  let thumbnail = media ? media.sizes.large : undefined;
  let alt = media ? media.alt : undefined;

  //If not available, use primary image
  if (!media) media = casestudy['_embedded'] ? casestudy['_embedded']['wp:featuredmedia'] : undefined;
  if (!thumbnail) thumbnail = (media && media.length) ? media['0'].source_url : undefined;
  if (!alt) alt = (media && media.length) ? media['0'].alt_text : undefined;

  return (
    <div className={`gallery__image ${active ? (casestudy.id === active.id ? 'active' : '') : ''}`}>
      {thumbnail && (
        <img src={thumbnail} alt={alt} />
      )}
    </div>
  )
}

export default GalleryImage;